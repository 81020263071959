import React, { useEffect, useState, useRef, useCallback } from 'react'
import { pdfjs, Document, Page } from 'react-pdf'
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { BuildingOffice2Icon, CalendarDaysIcon, EnvelopeIcon, PencilIcon, ShareIcon, ArrowDownTrayIcon, PrinterIcon, ArrowsPointingOutIcon, XMarkIcon } from "@heroicons/react/24/outline"
import { Fields } from '../components/docViewer/Fields';
import { LuRotateCcw, LuRotateCw } from "react-icons/lu";
import { FaSignature } from "react-icons/fa6";
import { useDispatch, useSelector } from 'react-redux';
import SignatureModal from '../components/docViewer/SignatureModal';
import { useLocation, useNavigate } from 'react-router-dom';
import { esignAPI, thirdView } from '../APIRoutes';
import { TbTextRecognition } from 'react-icons/tb';
import { BsPersonStanding } from 'react-icons/bs';
import { getCoordinates, setCurrentDocumentId, signDocument, updateCoordinates } from '../stores/DocumentSlice';
import EsignScheduledModal from '../components/EsignScheduledModal';
import { showMessage } from '../utils/helper';
import 'shepherd.js/dist/css/shepherd.css';
import Shepherd from 'shepherd.js';
import axios from 'axios';

export const DocViewer = () => {

    const fields = [
        { fieldId: '1', name: 'Signature', icon: PencilIcon },
        { fieldId: '2', name: 'Initial', icon: FaSignature },
        { fieldId: '3', name: 'Company', icon: BuildingOffice2Icon },
        { fieldId: '4', name: 'Email', icon: EnvelopeIcon },
        { fieldId: '5', name: 'Sign Date', icon: CalendarDaysIcon },
        { fieldId: '6', name: 'Current Date', icon: CalendarDaysIcon },
        { fieldId: '7', name: 'Job Title', icon: TbTextRecognition },
        { fieldId: '8', name: 'Full Name', icon: BsPersonStanding },
    ]

    const [viewMode, setViewMode] = useState('edit')
    const [totalPages, settotalPages] = useState(0);
    const [pageNumber, setpageNumber] = useState(1);
    const [selectedFields, setSelectedFields] = useState([])
    const [openSignatureModal, setOpenSignatureModal] = useState(false)
    const [openEsignScheduledModal, setOpenEsignScheduledModal] = useState(false)
    const [renderedPages, setRenderedPages] = useState({})
    const [url, setUrl] = useState('')
    const dispatch = useDispatch()

    const documentId = useSelector(state => state.document.currentDocumentId)
    const userAuthToken = useSelector(state => state.user.userToken)
    const selectedRecipient = useSelector(state => state.document.currentSelectedRecipient)
    const allRecipients = useSelector(state => state.document.recipientList)
    const signature = useSelector(state => state.document.selectedSignatureImage)

    const pageRefs = useRef([]);
    const observer = useRef(null); 
    const navigate = useNavigate()
    const location = useLocation()
    const fieldRefs = useRef([])
    const dragData = useRef({
      offsetX: 0,
      offsetY: 0
    });

    const dummyComponentData = [
        {
            ...fields[1],
            "id": 3700,
            "name": "Initial",
            "componentValue": "",
            "x": 278.1875,
            "y": 173,
            "page": 2
        },
        {
            ...fields[0],
            "id": 2194,
            "name": "Signature",
            "componentValue": "",
            "x": 445.6875,
            "y": 145.5,
            "page": 4
        },
        {
            ...fields[7],
            "id": 5787,
            "name": "Full Name",
            "componentValue": "",
            "x": 617.6875,
            "y": 395.5,
            "page": 4
        }
    ]

    function uploadCoordinates(esignScheduledData) {
        const data = {
            "docId": documentId,
            "esignComponentAndCoordinate": selectedFields.map((field) => {
                return ({
                    "id": field.id,
                    "componentName": field.name,
                    "componentValue": "",
                    "x_coordinates": field.x,
                    "y_coordinates": field.y,
                    "pageNo":  field.page,
                    recipientId: field.recipientId
                })
            }),
            "createTemplate": false,
            "esignScheduled":{
                "isEsignScheduled":false,
            }
        }
        
        if (esignScheduledData.isEsignScheduled) {
            const newDate = new Date(esignScheduledData.scheduledDate)
            data.esignScheduled = {
                'isEsignScheduled': esignScheduledData.isEsignScheduled,
                'day': `${newDate.getDate()}/${newDate.getMonth() + 1}/${newDate.getFullYear()}`,
                'time': `${newDate.getHours() > 12 ? newDate.getHours() - 12 : newDate.getHours()} ${newDate.getHours() > 11 ? 'PM' : 'AM'}`
            }
        }

        const headers = {
            AuthToken: userAuthToken
        }

        try {            
            dispatch(updateCoordinates(data, headers)).then((data) => showMessage(data.data.response.Message))
            setOpenEsignScheduledModal(false)
            navigate('/documents')
        } catch (error) {
            showMessage(error)
        }
    }

    function generateFourDigitNumber() {
        const min = 1000; // Smallest 4-digit number
        const max = 9999; // Largest 4-digit number
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    function handleOnDrop(e, pageNumber) {
        e.preventDefault();
      
        const fieldType = JSON.parse(e.dataTransfer.getData("fieldType"));
        const positions = JSON.parse(e.dataTransfer.getData("positions"));
        const pageRef = pageRefs.current[pageNumber];  // Get the ref for the page
        // Check if pageRef exists and is valid
        if (!pageRef) {
          console.error("Page reference is not available for page number:", pageNumber);
          return;
        }
      
        const rect = pageRef.getBoundingClientRect(); // Get the bounding box of the page
      
        if (fieldType.id) {
            const updatedFields = selectedFields.map((field) => {
                if (field.id === fieldType.id) {
                    return {
                        ...field,
                        x: e.clientX - rect.left - positions.x - 0.5, // X relative to the page
                        y: e.clientY - rect.top - positions.y - 0.5,  // Y relative to the page
                        page: pageNumber // Ensure the field is set to the correct page
                    };
                }
                return field;
            });
            setSelectedFields([...updatedFields]);
            return;
        }
      
        const selectedFieldType = fields.find((field) => field.fieldId === fieldType.fieldId);
        const selectedField = {
            ...selectedFieldType,
            id: generateFourDigitNumber(),
            x: e.clientX - rect.left - positions.x - 0.5,  // X relative to the page
            y: e.clientY - rect.top - positions.y - 0.5,   // Y relative to the page
            page: pageNumber,         // Store the page number the field is on
            recipientId: selectedRecipient.recipientId
        };
      
        setSelectedFields([...selectedFields, selectedField]);
    }
      

    function handleDragOver(e) {
        e.preventDefault();
    }

    function handleOnDragStart(e, field, index) {
        const rect = fieldRefs.current[index].getBoundingClientRect();
        // Calculate the offset between the cursor and the field's top-left corner
        dragData.current.offsetX = e.clientX - rect.left;
        dragData.current.offsetY = e.clientY - rect.top;
        
        e.dataTransfer.setData("fieldType", JSON.stringify(field));
        e.dataTransfer.setData("positions", JSON.stringify({x: dragData.current.offsetX , y: dragData.current.offsetY }))
    }

    function removeField(field) {
        const fields = selectedFields.filter((fieldType) => fieldType.id !== field.id)
        setSelectedFields([...fields])
    }

    function showSpecificModals(fieldType) {
        if (fieldType === 'Signature' || fieldType === 'Initial') setOpenSignatureModal(true)
    }

    function closeSignatureModal() {
        setOpenSignatureModal(false)
    }

    function closeEsignScheduledModal(data) {
        setOpenEsignScheduledModal(false);
        if (data) uploadCoordinates(data)
    }

    const scrollToPage = (pageIndex) => {
        const page = pageRefs.current[pageIndex];
        if (page) {
            page.scrollIntoView({ behavior: 'smooth' });
            setpageNumber(pageIndex)
        }
    };

    // Function to observe when a page is in view and update the current page number
    const observePages = useCallback(() => {
        if (observer.current) {
            observer.current.disconnect();  // Disconnect the old observer
        }


        // Create a new IntersectionObserver
        observer.current = new IntersectionObserver(
            (entries) => {
                const visiblePage = entries.find((entry) => entry.isIntersecting);
                if (visiblePage) {
                    const pageIndex = pageRefs.current.indexOf(visiblePage.target);
                    setpageNumber(pageIndex);  // Update current page number
                }
            },
            {
                threshold: 0.45,  // Trigger when 60% of the page is visible
            }
        );

        // Observe all pages
        pageRefs.current.forEach((page) => {
            if (page) observer.current.observe(page);
        });
    }, []);

    /**
     *
     * @param {Object} event
     *
     * This function will be called when pdf is loaded
     */
    function onDocLoad(event) {
        settotalPages(event.numPages);
    }

    const onRenderSuccess = (pageNumber) => {
        // Mark the page as rendered
        setRenderedPages((prevState) => ({
          ...prevState,
          [pageNumber]: true,
        }));
      };

    function setupCustomTour() {
        const tour = new Shepherd.Tour({
            useModalOverlay: true,
            defaultStepOptions: {
                scrollTo: { behavior: 'smooth', block: 'center' },
              cancelIcon: {
                enabled: true
              },
              classes: 'custom-tour-class',
              buttons: [
                {
                  text: 'Next',
                  action: Shepherd.next
                }
              ],
              popperOptions: {
                modifiers: [{ name: 'arrow', options: { element: '.shepherd-arrow' } }] // Ensures the arrow is shown
              }
            }
        });

        // Function to scroll to a specific page
        const scrollToPages = (pageNumber) => {
            return new Promise((resolve) => {
                if (renderedPages[pageNumber]) {
                    // Page is rendered, scroll to it
                    pageRefs.current[pageNumber].scrollIntoView({
                        behavior: 'smooth',
                        block: 'start'
                    });
                    resolve(); // Resolve the promise after scrolling
                } else {
                    // Wait for the page to be rendered before scrolling
                    const interval = setInterval(() => {
                        if (renderedPages[pageNumber]) {
                        clearInterval(interval);
                        pageRefs.current[pageNumber].scrollIntoView({
                        behavior: 'smooth',
                        block: 'start'
                        });
                        resolve();
                    }
                    }, 100); // Check every 100ms if the page is rendered
                }
            });
        };

        // Define Steps
        selectedFields.forEach((field, index) => {
            if (index === selectedFields.length - 1) {
                tour.addStep({
                    id: `step-${index+1}`,
                    text:  `${index+1} tour`,
                    attachTo: {
                        element: `#step-${index+1}`,
                        on: 'bottom'
                    },
                    buttons: [
                        {
                            text: 'Exit',
                            action: tour.cancel
                        },
                        {
                            text:  'Complete',
                            action: tour.complete
                        }
                    ],
                    beforeShowPromise: () => scrollToPages(field.page),
                })
                return;
            }
            tour.addStep({
                id: `step-${index+1}`,
                text:  `${index+1} tour`,
                attachTo: {
                    element: `#step-${index+1}`,
                    on: 'bottom'
                },
                buttons: [
                    {
                        text: 'Exit',
                        action: tour.cancel
                    },
                    {
                        text:  'Next',
                        action: tour.next
                    }
                ],
                beforeShowPromise: () => scrollToPages(field.page),
            })
        })

        // Start the tour when component loads
        tour.start();
    }

    useEffect(() => {
        // On load adding pdfjs worker
        pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
        
        observePages();
    }, [observePages, totalPages]);

    const fetchDocument = async (docId) => {
        try {
            const response = await fetch(`${esignAPI}/getDocument?documentId=${docId}`, {
                method: 'GET',
                headers: {
                'Content-Type': 'application/pdf',
                AuthToken: userAuthToken, // Modify based on your API's response type
                },
            });
          
          // Convert response to ArrayBuffer
          const data = await response.arrayBuffer();

          // Create a Blob from the ArrayBuffer
          const blob = new Blob([data], { type: 'application/pdf' });
          
          // Create an object URL from the Blob
          const blobUrl = URL.createObjectURL(blob);
          setUrl(blobUrl)
        } catch (e) {
            showMessage(e, 'error')
        }
    }

    const fetchDocumentforEsignThirdView = async (docId) => {
        try {
            // fetchDocument(docId)
            const response = await axios.get(`${thirdView}/getDocument?docId=${docId}`, {headers: {
                AuthToken: userAuthToken
            }})

            if (response.data.message === 'SUCCESS') {
                const documentUrl = response.data.response.documentUrl
                dispatch(getCoordinates({AuthToken: userAuthToken})).then((data) => { 
                    const allCoordinates = data.data.response
                    const xyz = allCoordinates.map((coordinate) => {
                        const selectedField = fields.find((field) => field.name === coordinate.componentName) 
                        return {
                            ...selectedField,
                            id: coordinate.id,
                            name: coordinate.componentName,
                            componentValue: coordinate.componentValue,
                            x: coordinate.x_coordinates,
                            y: coordinate.y_coordinates,
                            page: parseInt(coordinate.pageNo)
                        } 
                    })
                    setSelectedFields([...xyz])
                    setUrl(documentUrl)
                } )
            }
        } catch (error) {
            showMessage(error, 'error')
        }
    }

    const handleSaveDocument = () => {
        if (viewMode === 'edit') {
            setOpenEsignScheduledModal(true)
        } else {
            const headers = {
                'Content-Type': 'application/json',
                AuthToken: userAuthToken
            }
            const postData = {
                signMode: signature.type,
                base64Image: signature.img
            }
            try {
                dispatch(signDocument(postData, headers))
            } catch (error) {
                showMessage(error)
            }
        }
    }

    useEffect(() => {
        const docId = location.search.split('&')[0].split('?documentId=')[1]
        const viewType = location.search.split('&')[1].split('viewType=')[1]
        dispatch(setCurrentDocumentId(docId))
        setViewMode(viewType)
        if (viewType === 'edit') {
            if (!docId) {
                navigate('/uploadDocument')
            } else fetchDocument(docId)
        }
        else fetchDocumentforEsignThirdView(docId)
    }, [location, userAuthToken])

    useEffect(() => {
        if (viewMode === 'esignView') {
            setSelectedFields([...dummyComponentData])
        }
    }, [viewMode])

    useEffect(() => {
        if(viewMode === 'esignView' && Object.entries(renderedPages).length === totalPages) {
            setupCustomTour()
        }
    }, [viewMode, selectedFields, totalPages, renderedPages])

    return (
        <div className='overflow-hidden'>
            {openSignatureModal && <SignatureModal closeModal={() => closeSignatureModal()} />}
            {openEsignScheduledModal && <EsignScheduledModal closeModal={(data) =>  closeEsignScheduledModal(data)} />}
            <div className='bg-[#353535] py-4 px-10 flex items-center justify-between text-white'>
                <div className="flex items-center justify-center font-semibold text-lg">
                    Pdf File Name
                    <PencilIcon className='w-5 h-5 ml-3' />
                </div>
                <div className="flex justify-center items-center gap-1">
                    {`${pageNumber} / ${totalPages}`}
                </div>
                <div className='flex space-x-5'>
                    <button className="bg-slate-200 text-black px-6 cursor-pointer py-2 rounded-lg">
                        Save & Close
                    </button>
                    <button onClick={() => handleSaveDocument()} className="bg-green-600 text-white px-6 cursor-pointer py-2 rounded-lg">
                        Continue
                    </button>
                </div>
            </div>
            <div className='flex py-4 items-center px-10 justify-between'>
                <div className='flex space-x-5'>
                    <LuRotateCcw className='w-6 h-6' />
                    <LuRotateCw className='w-6 h-6' />
                    <PencilIcon className='w-6 h-6' />
                    <ArrowDownTrayIcon className='w-6 h-6' />
                    <PrinterIcon className='w-6 h-6' />
                    <ArrowsPointingOutIcon className='w-6 h-6' />
                    <span className='text-2xl -mt-1 flex justify-start items-start'>T</span>
                </div>
                <div>
                    <ShareIcon className='w-5 h-5' />
                </div>
            </div>
            <div className=" w-full h-screen flex justify-start items-start overflow-hidden">
                <div>
                     <Fields viewMode={viewMode} />
                </div>
                <div className="w-full h-full">
                    <div className="w-full bg-slate-100 h-full">
                        <div className="w-full bg-slate-100 p-4 h-full overflow-auto flex justify-center items-start">
                            <Document file={url}>
                                {Array.from({ length: totalPages }, (el, index) => (
                                    <Page key={`page_${index + 1}`} pageNumber={index + 1} pageIndex={index + 1} height={1500} width={1100} onDrop={(e) => handleOnDrop(e, index + 1)} onDragOver={handleDragOver} inputRef={el => pageRefs.current[index + 1] = el} className="relative my-4" onRenderSuccess={() => onRenderSuccess(index + 1)}>
                                        {
                                            selectedFields.map((field, fieldIndex) => {
                                                return (
                                                    <div key={field.id} >
                                                        {index + 1 === field.page && <div
                                                            className="flex justify-between"
                                                            onClick={() => { showSpecificModals(field.name)}}
                                                            style={{
                                                                position: 'absolute',
                                                                left: `${field.x}px`,
                                                                top: `${field.y}px`,
                                                            }}
                                                            >
                                                            <div id={`step-${fieldIndex+1}`}>
                                                                {/* <div> */}
                                                                    {viewMode === 'edit' && <span className='absolute -right-2 -top-2 bg-red-500 rounded-full !cursor-pointer text-white p-0.5 z-40' onClick={() => removeField(field)} draggable="false">
                                                                        <XMarkIcon className='w-4 h-4' />
                                                                    </span>}
                                                                    {viewMode === 'edit' ? <textarea style={{ resize: 'both' }} className={`flex relative justify-center items-center !cursor-move min-w-52 min-h-12 z-10 border-dashed border-2 border-${allRecipients.find(recipient => recipient.recipientId === field.recipientId).color}-600 bg-${allRecipients.find(recipient => recipient.recipientId === field.recipientId).color}-300 !group focus:outline-none`} draggable={viewMode === 'edit' ? true : false} onDragStart={(e) => handleOnDragStart(e, field, fieldIndex)} ref={el => fieldRefs.current[fieldIndex] = el} readOnly={viewMode === 'edit' ? true : false} value={field.name} /> : ['Signature', 'Initials'].includes(field.name) && signature.img ?
                                                                        <div><img src={signature.img} className='w-52 h-16 border-2 border-green-500 border-dashed !cursor-pointer relative z-10' alt="Uploaded"/></div>
                                                                    : 
                                                                    <textarea style={{ resize: 'both' }} className='flex relative justify-center items-center !cursor-pointer min-w-52 min-h-12 z-10 border-dashed border-2 border-green-500 bg-green-500/30 !group focus:outline-none' value={field.name} readOnly={viewMode === 'edit' ? true : false} />}
                                                                {/* </div> */}
                                                            </div>
                                                        </div>}
                                                    </div>
                                                )
                                            })
                                        }
                                    </Page>
                                ))}
                            </Document>
                        </div>
                    </div>
                </div>
                <div className="border-r-2 border-gray-400 w-96 py-2 h-full">
                    <div className="px-2 py-3 border-b-2 text-center font-semibold text-lg">
                        Documents
                    </div>
                    <div className="h-full pb-20">
                        <Document
                            className={
                                "flex flex-col justify-start items-center overflow-auto h-full"
                            }
                            file={url}
                            onLoadSuccess={onDocLoad}
                        >
                            {Array(totalPages)
                                .fill()
                                .map((_, index) => (
                                    <div
                                    key={index}
                                    onClick={() => scrollToPage(index + 1)} // Fix page number here
                                    className={`border-[4px] cursor-pointer relative rounded my-2 ${pageNumber === index + 1 ? "border-green-700" : ""}`}
                                    >
                                    <Page height={250} pageNumber={index + 1} />
                                    </div>
                                ))}
                        </Document>
                    </div>
                </div>
            </div>
        </div>
    )
}
