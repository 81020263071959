import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LuLoader2 } from 'react-icons/lu'
import { createAdmin } from '../../stores/SuperAdminSlice'
import { fetchPermissions } from '../../stores/UserStore'
import { showMessage } from '../../utils/helper'

const AdminDetails = () => {
    const [authProducts, setAuthProducts] = useState([])
    const [loading, setLoading] = useState(false)
    // const [pocDetails, setPocDetails] = useState([{id: Math.random() * 100, name: '', email: ''}])
    const [personalDetails, setPersonalDetails] = useState({
        userName: '',
        email: '',
        panNo: '',
        gstNo: '',
        panAttachment: '',
        gstAttachment: ''
    })
    const dispatch = useDispatch()
    const userAuthToken = useSelector(state => state.user.userToken)

    // function addPocDetail() {
    //     const newPocDetail = { id: Math.random() * 100, name: '', email: '' }
    //     setPocDetails([...pocDetails, newPocDetail])
    // }

    function handlePersonalDetailChange(event) {
        setPersonalDetails({ ...personalDetails, [event.target.name]: event.target.value })
    }

    // function handlePocDetailChange (event, pocId) {
    //     const newPocDetails = []
    //     pocDetails.forEach((poc) => {
    //         if (poc.id === pocId) {
    //             const newPoc = { ...poc, [event.target.name]: event.target.value }
    //             newPocDetails.push(newPoc)
    //         } else newPocDetails.push(poc)
    //     })
    //     setPocDetails([ ...newPocDetails ])
    // }

    function handleAuthTypeChange(product, index) {
        const newAuthTypeDetails = []
        authProducts.forEach((authProduct, ind) => {
            if (authProduct.permissionName === product.permissionName && ind === index) {
                const newDetail = { ...authProduct, selected: !authProduct.selected }
                newAuthTypeDetails.push(newDetail)
            } else newAuthTypeDetails.push(authProduct)
        })
        setAuthProducts([ ...newAuthTypeDetails ])
    }

    function createNewAdmin() {
        setLoading(true)
        const postData = {
            loginDetails: { emailId: personalDetails.email, password: personalDetails.password },
            userPermission: authProducts.filter((prod) => prod.selected),
        }
        const headers = {
            'Content-Type': 'application/json',
            AuthToken: userAuthToken
        }
        dispatch(createAdmin(postData, headers)).then((data) => {setLoading(false);})
    }

    useEffect(() => {
        if (userAuthToken) {
          dispatch(fetchPermissions({role: 'ADMIN'}, {AuthToken: userAuthToken, 'Content-Type': 'application/json'})).then((data) => {
            if (data.data.message === 'SUCCESS') {
                const allPermissions = data.data.response.permissionName
                const prods = allPermissions.map((product) => {
                    return {
                        selected: false,
                        permissionName: product
                    }
                })
                setAuthProducts([...prods])

            }
          }).catch((e) => {
            showMessage(e.response.data.Response.ErrorMessage, 'error')
          })
        }
    }, [userAuthToken])

  return (
    <div className='flex flex-col gap-y-8 mt-10 max-w-4xl mx-auto'>
        <span className='flex items-start text-2xl text-gray-500'>Personal Details</span>
        <div className='grid grid-cols-2 space-x-10'>
            <div className='flex flex-col items-start justify-center -mt-5'>
                <label htmlFor="emailId" className='ml-2 text-lg'>Email</label>
                <input type="text" id='emailId' name='email' className='border-2 border-gray-400 w-full rounded-lg px-3 py-2 '  value={personalDetails.email} onChange={handlePersonalDetailChange} />
            </div>  
        </div>
        <span className='flex items-start text-2xl text-gray-500'>Permissions</span>
        <div className='grid grid-cols-12 justify-between -mt-3 space-y-5'>
            {
                authProducts.map((product, index) => {
                    return (
                        <div key={index} className='flex items-center col-span-12 cursor-pointer justify-start space-x-2 w-fit' onClick={ () => handleAuthTypeChange(product, index)}>
                            <input type="checkbox" name="authProduct" readOnly checked={product.selected} className='w-3.5 h-3.5 cursor-pointer'  />
                            <label className='w-fit cursor-pointer'>{product.permissionName}</label>
                        </div>
                    )
                })
            }
        </div>
        <div className='flex justify-end mt-10 mb-20'>
            <button className='border-2 flex items-center justify-center text-white bg-blue-900 space-x-2 border-blue-900 hover:bg-blue-950 hover:border-blue-950 w-fit rounded-lg px-3 py-2' onClick={() => createNewAdmin()}>
                {loading && <LuLoader2 className='w-5 h-5 animate-spin text-white' />}
                <span>Create Admin</span>
            </button>
        </div>
    </div>
  )
}

export default AdminDetails
