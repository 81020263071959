import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchRecipients, updateCurrentSelectedRecipient, updateRecipientList } from '../../stores/DocumentSlice'
import { showMessage } from '../../utils/helper'

export const Recipients = ({ viewMode }) => {

  const [recipientList, setRecipientList] = useState([])
  const dispatch = useDispatch()
  const documentId = useSelector(state => state.document.currentDocumentId)
  const userAuthToken = useSelector(state => state.user.userToken)

  const colors = ["green", "red", "orange", "yellow", "lime", "emerald", "teal", "blue", "indigo", "fuchsia", "pink", "slate"]

  const fetchRecipientsList = async () => {
    try {
        const response = dispatch(fetchRecipients(documentId, { AuthToken: userAuthToken }))
        response.then((data) => {
            if (data.data.message === 'SUCCESS') {
                const list = data.data.response.map((recipient, index) => {
                  return {
                    ...recipient,
                    color: colors[index],
                    current: index === 0
                  }
                })
                setRecipientList([...list])
                dispatch(updateRecipientList(list))
                dispatch(updateCurrentSelectedRecipient(list[0]))
            }
        })
    } catch (error) {
        showMessage(error, 'error')
    }
  }
  
  const handleRecipientChange = (recipientId) => {
    let newRecipientList = recipientList.map((recipient) => {
      if (recipient.current === true) {
      return { ...recipient, current: false }
    } else return recipient})
    newRecipientList = newRecipientList.map((recipient) => {
      if (recipient.recipientId === recipientId) {
        return { ...recipient, current: true }
      }
      return recipient
    })
    const selectedRecipient = newRecipientList.find((recipient) => recipient.current)
    dispatch(updateCurrentSelectedRecipient(selectedRecipient))
    setRecipientList([...newRecipientList])
  }

  useEffect(() => {
    if (viewMode === 'edit') {
      fetchRecipientsList()
    }
  }, [viewMode, userAuthToken])

  return (
    <div className='py-4 flex flex-col items-start px-8 border-b-2 border-gray-300'>
      <h3 className='text-2xl text-gray-600 font-medium'>
        Recipients
      </h3>
      <p className='text-start pt-4'>
        Select a recipient for whom you need to add or request data:
      </p>
      <div className='flex flex-col items-start pt-4 space-y-5 w-full'>
        {
          recipientList.map((recipient) => {
            return (
              <div key={recipient.recipientId} className={`bg-${recipient.color}-300 px-3 items-center py-2 border-2 border-dashed ${recipient.current ? `border-${recipient.color}-600` : 'opacity-70'} w-full cursor-pointer`} onClick={() => handleRecipientChange(recipient.recipientId)}>
                <span className='flex'>{recipient.name}</span>
                <span className='flex'>{recipient.email}</span>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}
