import { Button, Dialog, DialogPanel, DialogTitle, DialogBackdrop } from '@headlessui/react'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { sendOtpForForgotPassword, verifyOtpForForgotPassword } from '../stores/UserStore'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { showMessage } from '../utils/helper'

// {
//   "code": "0000",
//   "message": "SUCCESS",
//   "response": {
//       "forgotPasswordId": "1728000913015",
//       "message": "Otp has been sent"
//   }
// }

export default function ForgotPasswordModal({ closeModal }) {
  let [isOpen, setIsOpen] = useState(true)
  const [credentials, setCredentials] = useState({ email: '', password: '', otp: '' })
  const [id, setId] = useState('')
  const [showNewPassword, setShowNewPassword] = useState(false)
  const dispatch = useDispatch()

  const handleChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value })
  }

  function handleForgotPassword() {
    if (credentials.email) {
      const emailResponse = dispatch(sendOtpForForgotPassword(credentials.email))
      emailResponse.then((data) => {
        setId(data.data.response.forgotPasswordId)
        setShowNewPassword(true)
      }).catch((e) => {
        showMessage(e.response.data.Response.response, 'error')
        closeModal()
      })
    }
  }

  function handleVerifyOtp() {
    if (credentials.otp && credentials.password) {
      const data = {
        forgotPasswordId: id,
        otp: credentials.otp,
        newPassword: credentials.password,
      }
      const otpResponse = dispatch(verifyOtpForForgotPassword(data))
      otpResponse.then((data) => {
        closeModal()
        showMessage(data.data.response.message)
      })
    }
  }

  function close() {
    setIsOpen(false)
  }

  return (
    <>
      <Dialog open={isOpen} as="div" className="relative z-10 focus:outline-none" onClose={close}>
        {/* The backdrop, rendered as a fixed sibling to the panel container */}
        <DialogBackdrop className="fixed inset-0 bg-black/50" />
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <DialogPanel
              transition
              className="w-full max-w-lg px-10 rounded-xl bg-white p-6 backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0"
            >
              <div className='flex justify-end items-end'>
                <XMarkIcon onClick={() => closeModal()} className='text-red-500 w-6 h-6 cursor-pointer hover:text-red-600' />
              </div>
              <DialogTitle as="h3" className="text-xl mt-5 font-medium">
                Forgot Password?
              </DialogTitle>
              {!showNewPassword && <div className='mt-8'>
                  <label htmlFor="email" className="block ml-2 text-start text-lg font-medium leading-6 text-gray-900">
                    Email address
                  </label>
                  <div className="mt-2">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      required
                      autoComplete="email"
                      value={credentials.email}
                      onChange={handleChange}
                      className="block w-full rounded-lg border-0 py-3 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"
                    />
                  </div>
                </div>}
              {showNewPassword && <div>
                <div className='mt-8'>
                  <label htmlFor="otp" className="block ml-2 text-start text-lg font-medium leading-6 text-gray-900">
                    OTP
                  </label>
                  <div className="mt-2">
                    <input
                      id="otp"
                      name="otp"
                      type="text"
                      required
                      value={credentials.otp}
                      onChange={handleChange}
                      className="block w-full rounded-lg border-0 py-3 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"
                    />
                  </div>
                </div>
                <div className='mt-8'>
                  <label htmlFor="password" className="block ml-2 text-start text-lg font-medium leading-6 text-gray-900">
                    New Password
                  </label>
                  <div className="mt-2">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      required
                      value={credentials.password}
                      onChange={handleChange}
                      className="block w-full rounded-lg border-0 py-3 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"
                    />
                  </div>
                </div>
                </div>}
              <div className="mt-8 mb-8">
                {!showNewPassword && <Button
                  className="inline-flex items-center gap-2 rounded-md bg-gray-700 py-1.5 px-3 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-600 data-[focus]:outline-1 data-[focus]:outline-white data-[open]:bg-gray-700"
                  onClick={() => handleForgotPassword()}
                >
                  Send Otp
                </Button>}
                {showNewPassword && <Button
                  className="inline-flex items-center gap-2 rounded-md bg-gray-700 py-1.5 px-3 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-600 data-[focus]:outline-1 data-[focus]:outline-white data-[open]:bg-gray-700"
                  onClick={() => handleVerifyOtp()}
                >
                  Update Password
                </Button>}
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </>
  )
}
