import { DocumentTextIcon, HomeIcon } from '@heroicons/react/24/outline'
import { HiOutlineTemplate } from "react-icons/hi";
import { GrDocumentTime, GrDocumentUser } from "react-icons/gr";
import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

export const Sidebar = () => {

    const [menus, setMenus] = useState([
        { name: 'Home', icon: HomeIcon, current: true, href: '/' },
        { name: 'Documents', icon: DocumentTextIcon, current: false, href: '/documents' },
        { name: 'Templates', icon: HiOutlineTemplate, current: false, href: '/' },
        { name: 'Sign Forms', icon: GrDocumentUser, current: false, href: '/' },
        { name: 'Reports', icon: GrDocumentTime, current: false, href: '/' },
    ])

    const [showSidebar, setShowSidebar] = useState(true)
    const location = useLocation()
    const navigate = useNavigate()

    function switchMenu(menu) {
        let updatedMenus = menus.map((x) => { x.current = false; return x; })
        updatedMenus = menus.map((x) => {
            if (x.name === menu.name) {
                x.current = true
            }
            return x;
        })
        setMenus([...updatedMenus])
        navigate(menu.href)
    }

    useEffect(() => {
        if (['/documentViewer', '/login', '/validateEsign'].includes(location.pathname)) {
            setShowSidebar(false)
        } else setShowSidebar(true)
    }, [location])

    return (
        <div>        
            {showSidebar && <div className='w-96 bg-[#353535] h-screen flex flex-col pt-28 space-y-5 p-4 px-10'>
                {
                    menus.map((menu) => {
                        return (
                            <button key={menu.name} className={[menu.current ? 'bg-green-600 text-white text-lg font-medium flex items-center space-x-4 py-3 rounded-lg px-5' : 'text-white text-lg font-medium flex items-center space-x-4 py-3 rounded-lg px-5']} onClick={() => switchMenu(menu)}>
                                <menu.icon className='w-8 h-8' />
                                <span>{menu.name}</span>
                            </button>
                        )
                    })
                }
            </div>}
        </div>
    )
}
