import React from 'react'
import Title from '../../components/generic/Title'
import { useNavigate } from 'react-router-dom'

export const ManageAdmin = () => {
    const navigate = useNavigate()
  return (
    <div className='mt-16 w-full'>
        <Title title="Manage Admin" />
        <div className='px-16 mt-16 flex items-end justify-end'>
            <div onClick={() => navigate('/createAdmin')} className='bg-blue-800 hover:bg-blue-900 w-fit cursor-pointer py-3 px-4 rounded-lg text-white'>Create Admin</div>
        </div>
        <div className='w-full px-16 mt-6 h-60'>
            <div className='border rounded-lg h-full flex items-center justify-center'>
                No Admins found!
            </div>
        </div>
    </div>
  )
}
