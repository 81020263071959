import React from 'react'
import Title from '../../components/generic/Title'
import UserDetails from '../../components/admin/UserDetails'

const CreateUser = () => {
  return (
    <div className='mt-16 w-full'>
      <Title title="Create User" />
      <UserDetails />
    </div>
  )
}

export default CreateUser
