import React, { useEffect } from 'react';
import Shepherd from 'shepherd.js';
import 'shepherd.js/dist/css/shepherd.css';

export const Test = () => {

  useEffect(() => {
    // Initialize the tour
    const tour = new Shepherd.Tour({
      defaultStepOptions: {
        scrollTo: true,
        cancelIcon: {
          enabled: true
        },
        classes: 'custom-tour-class',
        buttons: [
          {
            text: 'Next',
            action: Shepherd.next
          }
        ]
      }
    });

    // Define steps of the tour
    tour.addStep({
      id: 'step-1',
      text: 'Welcome to the first feature!',
      attachTo: {
        element: '#first-step',
        on: 'bottom'  // Tooltip position (top, bottom, left, right)
      },
      buttons: [
        {
          text: 'Exit',
          action: tour.cancel  // Exit the tour
        },
        {
          text: 'Next',
          action: tour.next
        }
      ]
    });

    tour.addStep({
      id: 'step-2',
      text: 'This is the second feature. Pay attention!',
      attachTo: {
        element: '#second-step',
        on: 'bottom'
      },
      buttons: [
        {
          text: 'Back',
          action: tour.back
        },
        {
          text: 'Next',
          action: tour.next
        }
      ]
    });

    tour.addStep({
      id: 'step-3',
      text: 'Here is the final feature.',
      attachTo: {
        element: '#fourth-step',
        on: 'top'
      },
      buttons: [
        {
          text: 'Back',
          action: tour.back
        },
        {
          text: 'Finish',
          action: tour.next  // Finish the tour
        }
      ]
    });
    tour.addStep({
      id: 'step-4',
      text: 'Here is the final feature.',
      attachTo: {
        element: '#third-step',
        on: 'top'
      },
      buttons: [
        {
          text: 'Back',
          action: tour.back
        },
        {
          text: 'Finish',
          action: tour.complete  // Finish the tour
        }
      ]
    });


    // Start the tour when component loads
    tour.start();
  }, []);

  return (
    <div className='mt-20 flex-col space-y-52'>
      <div id='first-step' className="first-step  ml-20 flex bg-red-300">This is the first step!</div>
      <div id='second-step' className="second-step ml-60">This is the second step!</div>
      <div id='fourth-step' className="fourth-step">This is the fourth and final step!</div>
      <div id='third-step' className="third-step">This is the third and final step!</div>
    </div>
  );
};
