import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createUser, fetchProducts } from '../../stores/AdminSlice'
import { LuLoader2 } from 'react-icons/lu'
import { fetchPermissions } from '../../stores/UserStore'
import { showMessage } from '../../utils/helper'

const UserDetails = () => {
    const products = useSelector(state => state.admin.products)
    const userAuthToken = useSelector(state => state.user.userToken)
    const [authProducts, setAuthProducts] = useState([])
    const [permissions, setPermissions] = useState([])
    const [loading, setLoading] = useState(false)
    const [pocDetails, setPocDetails] = useState([{id: Math.random() * 100, name: '', email: ''}])
    const [personalDetails, setPersonalDetails] = useState({
        userName: '',
        email: '',
        panNo: '',
        gstNo: '',
        companyName: '',
        panAttachment: '',
        gstAttachment: ''
    })
    const dispatch = useDispatch()

    function addPocDetail() {
        const newPocDetail = { id: Math.random() * 100, name: '', email: '' }
        setPocDetails([...pocDetails, newPocDetail])
    }

    function handlePersonalDetailChange(event) {
        setPersonalDetails({ ...personalDetails, [event.target.name]: event.target.value })
    }

    function handlePocDetailChange (event, pocId) {
        const newPocDetails = []
        pocDetails.forEach((poc) => {
            if (poc.id === pocId) {
                const newPoc = { ...poc, [event.target.name]: event.target.value }
                newPocDetails.push(newPoc)
            } else newPocDetails.push(poc)
        })
        setPocDetails([ ...newPocDetails ])
    }

    function handleAuthTypeChange(product, index) {
        const newAuthTypeDetails = []
        authProducts.forEach((authProduct, ind) => {
            if (authProduct.name === product.name && ind === index) {
                const newDetail = { ...authProduct, selected: !authProduct.selected }
                newAuthTypeDetails.push(newDetail)
            } else newAuthTypeDetails.push(authProduct)
        })
        setAuthProducts([ ...newAuthTypeDetails ])
    }

    function handlePermissionChange(product, index) {
        const newAuthTypeDetails = []
        permissions.forEach((permission, ind) => {
            if (permission.name === product.name && ind === index) {
                const newDetail = { ...permission, selected: !permission.selected }
                newAuthTypeDetails.push(newDetail)
            } else newAuthTypeDetails.push(permission)
        })
        setPermissions([ ...newAuthTypeDetails ])
    }

    function createNewUser() {
        setLoading(true)
        const postData = {
            userPOCDetails: pocDetails.map((poc) => {return {name: poc.name, emailId: poc.email}}),
            loginDetails: { emailId: personalDetails.email, password: personalDetails.password },
            userCompanyDetails: {
                companyName: personalDetails.companyName,
                panNo: personalDetails.panNo,
                gstNo: personalDetails.gstNo,
                panAttachment: personalDetails.panAttachment, //id of mongoDB
                gstAttachment: personalDetails.gstAttachment //id of mongoDB
            },
            apiEnabled: [
                {
                    "productId":"CLIENT_LOGIN"  //id of mongoDB
                },
                {
                    "productId":"ESIGN_MOBILE_AUTH"  //id of mongoDB
                }
            ],
            products: authProducts.filter((prod) => prod.selected).map((prod) => {return {productId: prod.name}}),
            userPermissions: [],
            userName: personalDetails.userName
        }
        const headers = {
            'Content-Type': 'application/json',
            AuthToken: userAuthToken
        }
        dispatch(createUser(postData, headers))
        .then((data) => {showMessage(data.data.response.Message)})
        .catch((e) => { showMessage(e.response.data.Response.response, 'error') })
        .finally(() => setLoading(false) )
    }

    useEffect(() => {
        const prods = products.map((product) => {
            return {
                selected: false,
                name: product
            }
        })
        setAuthProducts([...prods])
    }, [products])

    useEffect(() => {
        if (userAuthToken) {
            dispatch(fetchProducts({AuthToken: userAuthToken}))
            dispatch(fetchPermissions({role: 'USER'}, {AuthToken: userAuthToken, 'Content-Type': 'application/json'})).then((data) => {
              if (data.data.message === 'SUCCESS') {
                const allPermissions = data.data.response
                const perms = allPermissions.map((permission) => {
                    return {
                        selected: false,
                        name: permission
                    }
                })
                setPermissions([...perms])
              }
            }).catch((e) => {showMessage(e, 'error')})
        }
    }, [userAuthToken])

  return (
    <div className='flex flex-col gap-y-8 mt-10 max-w-4xl mx-auto'>
        <span className='flex items-start text-2xl text-gray-500'>Personal Details</span>
        <div className='grid grid-cols-2 space-x-10 -mt-5'>
            <div className='flex flex-col items-start justify-center'>
                <label htmlFor="userName" className='ml-2 text-lg'>User Name</label>
                <input type="text" id='userName' name='userName' className='border-2 border-gray-400 w-full rounded-lg px-3 py-2 ' value={personalDetails.userName} onChange={handlePersonalDetailChange} />
            </div>
            <div className='flex flex-col items-start justify-center'>
                <label htmlFor="emailId" className='ml-2 text-lg'>Email</label>
                <input type="text" id='emailId' name='email' className='border-2 border-gray-400 w-full rounded-lg px-3 py-2 '  value={personalDetails.email} onChange={handlePersonalDetailChange} />
            </div>
        </div>
        <span className='flex items-start text-2xl text-gray-500'>Company Details</span>
        <div className='grid grid-cols-3 space-x-10 -mt-5'>
        <div className='flex flex-col items-start justify-center'>
                <label htmlFor="companyName" className='ml-2 text-lg'>Company Name</label>
                <input type="text" id='companyName' name='companyName' className='border-2 border-gray-400 w-full rounded-lg px-3 py-2 '  value={personalDetails.companyName} onChange={handlePersonalDetailChange} />
            </div>
            <div className='flex flex-col items-start justify-center'>
                <label htmlFor="panNo" className='ml-2 text-lg'>PAN Number</label>
                <input type="text" id='panNo' name='panNo' className='border-2 border-gray-400 w-full rounded-lg px-3 py-2 '  value={personalDetails.panNo} onChange={handlePersonalDetailChange} />
            </div>
            <div className='flex flex-col items-start justify-center'>
                <label htmlFor="gstNo" className='ml-2 text-lg'>GST Number</label>
                <input type="text" id='gstNo' name='gstNo' className='border-2 border-gray-400 w-full rounded-lg px-3 py-2 '  value={personalDetails.gstNo} onChange={handlePersonalDetailChange} />
            </div>
        </div>
        <span className='flex items-start text-2xl text-gray-500'>Person of Contact Details</span>
        {
            pocDetails.map((poc) => {
                return (
                    <div key={poc.id} className='grid -mt-5 grid-cols-2 space-x-10'>
                        <div className='flex flex-col items-start justify-center'>
                            <label htmlFor="name" className='ml-2 text-lg'>Name</label>
                            <input type="text" id='name' name='name' className='border-2 border-gray-400 w-full rounded-lg px-3 py-2 ' value={poc.name} onChange={(e) => handlePocDetailChange(e, poc.id)} />
                        </div>
                        <div className='flex flex-col items-start justify-center'>
                            <label htmlFor="email" className='ml-2 text-lg'>Email</label>
                            <input type="text" id='email' name='email' className='border-2 border-gray-400 w-full rounded-lg px-3 py-2 ' value={poc.email} onChange={(e) => handlePocDetailChange(e, poc.id)} />
                        </div>
                    </div>
                )
            })
        }
        <button className='border-2 flex items-center justify-start w-fit hover:bg-gray-600 hover:text-white border-gray-400 rounded-lg px-3 py-2' onClick={() => addPocDetail()}>
            + Add new Person of Contact
        </button>
        <span className='flex items-start text-2xl text-gray-500'>Products</span>
        <div className='grid grid-cols-12 justify-between -mt-5'>
            {
                authProducts.map((product, index) => {
                    return (
                        <div key={index} className='flex items-center col-span-4 cursor-pointer justify-start space-x-2 w-fit' onClick={ () => handleAuthTypeChange(product, index)}>
                            <input type="checkbox" name="authProduct" readOnly checked={product.selected} className='w-3.5 h-3.5 cursor-pointer'  />
                            <label className='w-fit cursor-pointer'>{product.name}</label>
                        </div>
                    )
                })
            }
        </div>
        <span className='flex items-start text-2xl text-gray-500'>Permissions</span>
        <div className='grid grid-cols-12 justify-between -mt-5'>
            {
                permissions.map((permission, index) => {
                    return (
                        <div key={index} className='flex items-center col-span-4 cursor-pointer justify-start space-x-2 w-fit' onClick={ () => handlePermissionChange(permission, index)}>
                            <input type="checkbox" name="authProduct" readOnly checked={permission.selected} className='w-3.5 h-3.5 cursor-pointer'  />
                            <label className='w-fit cursor-pointer'>{permission.name}</label>
                        </div>
                    )
                })
            }
        </div>
        <div className='flex justify-end mt-10 mb-20'>
            <button className='border-2 flex items-center justify-center text-white bg-blue-900 space-x-2 border-blue-900 hover:bg-blue-950 hover:border-blue-950 w-fit rounded-lg px-3 py-2' onClick={() => createNewUser()}>
                {loading && <LuLoader2 className='w-5 h-5 animate-spin text-white' />}
                <span>Create User</span>
            </button>
        </div>
    </div>
  )
}

export default UserDetails
