import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { adminAPI, authAPI, host } from "../APIRoutes";
import Cookies from "js-cookie";
import { checkValidityOfToken } from "../utils/helper";

const initialState = {
    loggedIn: [],
    userToken: "",
    userRole: "",
    userPermissions: []
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setLoggedInStatus: (state, action) => {
            state.loggedIn = action.payload
        }, 
        setToken: (state, action) => {
            state.userToken = action.payload
        },
        setUserRole: (state, action) => {
            state.userRole = action.payload
        },
        setPermissions: (state, action) => {
            state.userPermissions = action.payload
        }
    }
})

export const regenerateToken = (token) => {
    return async (dispatch) => {
        const headers = { 'AuthToken': token }
        const response = await axios.get(`${host}/regenerate-token`, { headers } )
        if (response.data.ErrorMessage) return 'LOGIN'
        else if (response.data.response['RegeneratedToken']) { 
            dispatch(setToken(response.data.response['RegeneratedToken']));      
            Cookies.set('AuthToken', response.data.response['RegeneratedToken'], { expires: 7 }); // Set a cookie with a 7-day expiration
            return response.data.response['RegeneratedToken']; 
        }
    }
}

export const logoutUser = (headers) => {
    return async (dispatch) => {
        const validatedToken = checkValidityOfToken(headers.AuthToken)
        if  (validatedToken === 'REGENERATE') {
            const data = await dispatch(regenerateToken(headers.AuthToken))
            headers.AuthToken = data
        }
        const response = await axios.get(`${host}/logout`, { headers })
        return response
    }
}

export const sendOtpForForgotPassword = (emailId) => {
    return async () => {
        const response = await axios.get(`${authAPI}/forgotPassword/sentOtp?EmailId=${emailId}`)
        return response
    }
}

export const verifyOtpForForgotPassword = (postData) => {
    return async () => {
        const response = await axios.post(`${authAPI}/updatePassword/verifyOtp`, postData)
        return response
    }
}

export const fetchPermissions = (postData, headers) => {
    return async (dispatch) => {
        const validatedToken = checkValidityOfToken(headers.AuthToken)
        if  (validatedToken === 'REGENERATE') {
            const data = await dispatch(regenerateToken(headers.AuthToken))
            headers.AuthToken = data
        }
        const response = await axios.post(`${adminAPI}/getAllPermission`, postData, { headers })
        return response
    }
}

export const getAllUsers = (body, headers) => {
    return async (dispatch) => {
        const validatedToken = checkValidityOfToken(headers.AuthToken)
        if  (validatedToken === 'REGENERATE') {
            const data = await dispatch(regenerateToken(headers.AuthToken))
            headers.AuthToken = data
        }
        const response = await axios.post(`${adminAPI}/getAllUsers?pageNo=0&dateSize=30`, body, { headers })
        return response
    }
}

export const { setLoggedInStatus, setToken, setUserRole, setPermissions } = userSlice.actions
export default userSlice.reducer