import { Button, Dialog, DialogPanel, DialogTitle, DialogBackdrop } from '@headlessui/react'
import { useState } from 'react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import SelectBox from './generic/SelectBox'

export default function EsignScheduledModal({ closeModal }) {
  const [isOpen, setIsOpen] = useState(true)
  const [isEsignScheduled, setIsEsignScheduled] = useState(false)
  const [scheduledDate, setScheduledDate] = useState('')

  function close() {
    setIsOpen(false)
  }

  function handleSaveModal () {
    const data = {
      isEsignScheduled: isEsignScheduled,
    }
    if (isEsignScheduled) {
      data.scheduledDate = scheduledDate
    }
    closeModal(data)
  }

  return (
    <>
      <Dialog open={isOpen} as="div" className="relative z-50 focus:outline-none" onClose={close}>
        {/* The backdrop, rendered as a fixed sibling to the panel container */}
        <DialogBackdrop className="fixed inset-0 bg-black/50" />
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <DialogPanel
              transition
              className="w-full max-w-lg px-10 rounded-xl bg-white p-6 backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0"
            >
              <div className='flex justify-between items-end mb-10'>
                <DialogTitle as="h3" className="text-xl mt-5 font-medium">
                    Esign Settings
                </DialogTitle>
                <XMarkIcon onClick={() => closeModal()} className='text-red-500 w-6 h-6 cursor-pointer hover:text-red-600' />
              </div>

                <div className='flex justify-center items-center space-x-5'>
                    <span className='w-full'>Is Esign Scheduled?</span>
                    <SelectBox list={[ {'id': '1', 'name': 'False'}, {'id': '2', 'name': 'True'} ]} updateSelectedValue={(value) => setIsEsignScheduled(value.name === 'True')} />
                </div>

                {isEsignScheduled && <div className='flex justify-center my-5 items-center space-x-5'>
                    <span className='w-full'>Date & Time</span>
                    <input type="datetime-local" name="" id="" className='border border-gray-300 py-2 px-2 rounded-lg w-full' onChange={(e) => setScheduledDate(e.target.value)} />    
                </div>}

              <div className="mt-8 mb-8">
                <Button
                  className="inline-flex items-center gap-2 rounded-md bg-gray-700 py-1.5 px-3 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-600 data-[focus]:outline-1 data-[focus]:outline-white data-[open]:bg-gray-700"
                  onClick={() => handleSaveModal()}
                >
                  Save Coordinates
                </Button>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </>
  )
}
