import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    uploadedDocuments: []
}

export const filesSlice = createSlice({
    name: 'files',
    initialState,
    reducers: {
        setUploadedDocument: (state, action) => {
            state.uploadedDocuments = action.payload
        }
    }
})

export const { setUploadedDocument } = filesSlice.actions
export default filesSlice.reducer
