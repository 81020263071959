import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { superAdminAPI } from "../APIRoutes";
import { checkValidityOfToken } from "../utils/helper";
import { regenerateToken } from "./UserStore";

const initialState = {
    products: []
}

export const superAdminSlice = createSlice({
    name: 'superAdmin',
    initialState,
    reducers: {
        setProducts: (state, action) => {
            state.products = action.payload
        }
    }
})

export const createAdmin = (postData, headers) => {
    return async (dispatch) => {
        const validatedToken = checkValidityOfToken(headers.AuthToken)
        if  (validatedToken === 'REGENERATE') {
            const data = await dispatch(regenerateToken(headers.AuthToken))
            headers.AuthToken = data
        }
        const response = await axios.post(`${superAdminAPI}/createAdmin`, postData, { headers })
        return response.data.response
    }
}

export const { setProducts } = superAdminSlice.actions
export default superAdminSlice.reducer
