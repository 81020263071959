import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { adminAPI } from "../APIRoutes";
import { checkValidityOfToken } from "../utils/helper";
import { regenerateToken } from "./UserStore";

const initialState = {
    products: []
}

export const adminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {
        setProducts: (state, action) => {
            state.products = action.payload
        }
    }
})

export const fetchProducts = (headers) => {
    return async (dispatch) => {
        const validatedToken = checkValidityOfToken(headers.AuthToken)
        if  (validatedToken === 'REGENERATE') {
            const data = await dispatch(regenerateToken(headers.AuthToken))
            headers.AuthToken = data
        }
        const response = await axios.get(`${adminAPI}/getAllProducts`, { headers })
        if (response.data.message === 'SUCCESS') {
            dispatch(setProducts(response.data.response.productsName))
        }
        return response.data.response
    }
}

export const createUser = (postData, headers) => {
    return async (dispatch) => {
        const validatedToken = checkValidityOfToken(headers.AuthToken)
        if  (validatedToken === 'REGENERATE') {
            const data = await dispatch(regenerateToken(headers.AuthToken))
            headers.AuthToken = data
        }
        const response = await axios.post(`${adminAPI}/createUser`, postData, { headers })
        return response
    }
}

export const { setProducts } = adminSlice.actions
export default adminSlice.reducer
