import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { esignAPI, thirdView } from "../APIRoutes";
import { checkValidityOfToken } from "../utils/helper";
import { regenerateToken } from "./UserStore";

const initialState = {
    currentDocumentId: '',
    currentDocument: null,
    recipientList: [],
    currentSelectedRecipient: {},
    selectedSignatureImage: {}
}

export const documentSlice = createSlice({
    name: 'files',
    initialState,
    reducers: {
        setCurrentDocumentId: (state, action) => {
            state.currentDocumentId = action.payload
        },
        setCurrentDocument: (state, action) => {
            state.currentDocument = action.payload
        },
        updateRecipientList: (state, action) => {
            state.recipientList = action.payload
        },
        updateCurrentSelectedRecipient: (state, action) => {
            state.currentSelectedRecipient = action.payload
        },
        updateSelectedSignatureImage: (state, action) => {
            state.selectedSignatureImage = action.payload
        }
    }
})

export const updateCoordinates = (postData, headers) => {
    return async (dispatch) => {
        const validatedToken = checkValidityOfToken(headers.AuthToken)
        if  (validatedToken === 'REGENERATE') {
            const data = await dispatch(regenerateToken(headers.AuthToken))
            headers.AuthToken = data
        }
        const response = axios.post(`${esignAPI}/updateEsignCoordinates`, postData, { headers } )
        return response
    }
}

export const verifyEsignDocument = (postData) => {
    return async () => {
        const response = axios.post(`${thirdView}/verifyEsign`, postData, { 'Content-Type': 'application/json' })
        return response
    }
}

export const verifyEsignDocumentOtp = (postData) => {
    return async () => {
        const response = await axios.post(`${thirdView}/verifyEsignOtp`, postData, { 'Content-Type': 'application/json' })
        return response
    }
}

export const getCoordinates = (headers) => {
    return async () => {
        const response =  await axios.get(`${thirdView}/getCoordinates`, {headers})
        return response
    }
}

export const fetchRecipients = (docId, headers) => {
    return async () => {
        const response = await axios.get(`${esignAPI}/getRecipients?docId=${docId}`, {headers})
        return response
    }
}

export const signDocument = (postData, headers) => {
    return async () => {
        const response = await axios.post(`${thirdView}/signDocument`, postData, { headers })
        return response
    }
}

export const { setCurrentDocumentId, setCurrentDocument, updateRecipientList, updateCurrentSelectedRecipient, updateSelectedSignatureImage } = documentSlice.actions
export default documentSlice.reducer
