import React, { useEffect } from 'react'
import Title from '../../components/generic/Title'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getAllUsers } from '../../stores/UserStore'

export const ManageUser = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const userAuthToken = useSelector(state => state.user.userToken)
    useEffect(() => {
        const body = {
            "name" : "",
            "createdDateFrom":"",
            "createdDateTo":"",
            "companyName":"",
            "email":""
        }
        const headers = {
            'AuthToken': userAuthToken
        }
        dispatch(getAllUsers(body, headers))
    }, [userAuthToken])
  return (
    <div className='mt-16 w-full'>
        <Title title="Manage User" />
        <div className='px-16 mt-16 flex items-end justify-end'>
            <div onClick={() => navigate('/createUser')} className='bg-blue-800 hover:bg-blue-900 w-fit cursor-pointer py-3 px-4 rounded-lg text-white'>Create User</div>
        </div>
        <div className='w-full px-16 mt-6 h-60'>
            <div className='border rounded-lg h-full flex items-center justify-center'>
                No Users found!
            </div>
        </div>
    </div>
  )
}
