import { ChevronDownIcon, DocumentTextIcon, PaperClipIcon } from '@heroicons/react/24/outline'
import React, { useState, useRef, useEffect } from 'react'
import SelectBox from '../components/generic/SelectBox'
import { Switch } from '@headlessui/react'
import { useDispatch, useSelector } from 'react-redux'
import { setUploadedDocument } from '../stores/FilesSlice'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { esignAPI } from '../APIRoutes'
import { setCurrentDocumentId } from '../stores/DocumentSlice'
import { showMessage } from '../utils/helper'

export const UploadDocument = () => {

    const [allRecipients, setAllRecipients] = useState([{ email: '', mobileNum: '', name: '' }])
    const [enabled, setEnabled] = useState([])
    const [documents, setDocuments] = useState([])
    const [files, setFiles] = useState([])
    const [formData, setFormData] = useState(
        {
            documentName: '',
            dayToComplete: `${getFutureDate(7)}`,
            agreementValidUntil: `${getFutureDate(2, 'years')}`,
            automaticReminder: true,
            reminderInDays: 1,
            deliveryMode: 'EMAIL',
            authType: 'EMAIL',
            visibleEsignSetting: false
        }
    )

    const fileInputRef = useRef(null)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const userAuthToken = useSelector(state => state.user.userToken)

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            if (!formData.documentName) {
                const a = { ...formData, documentName: selectedFile.name };
                setFormData(a)
            }
            setDocuments([...documents, URL.createObjectURL(selectedFile)])
            setFiles([...files, selectedFile])
        }
    };

    function getFutureDate(days, type='days') {
        const currentDate = new Date();
        if (type === 'years') {
            currentDate.setFullYear(currentDate.getFullYear() + days)
        }else {
            currentDate.setDate(currentDate.getDate() + days);
        }
    
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
    
        return `${year}-${month}-${day}`;
    }

    const validateForm = () => {
        if (!files.length) { showMessage('Please select atleast one File', 'error'); return false;}
        if (!formData.documentName) { showMessage('Please fill the document Name', 'error'); return false;}
        if (!formData.agreementValidUntil) { showMessage('Please fill date for validity of the agreement', 'error'); return false;}
        if (!formData.dayToComplete) { showMessage('Please fill date for expiry time', 'error'); return false;}
        if (allRecipients.some((recipients) => !recipients.email || !recipients.name || !recipients.mobileNum)) { 
            showMessage('Please add recipients data', 'error'); return false;
        }
        if (new Date(formData.dayToComplete).getTime() < new Date().getTime()) {showMessage('Please select the expiry date greater than current date', 'error'); return false;}
        if (new Date(formData.dayToComplete) > new Date(getFutureDate(15))) {showMessage('Please select the expiry date less than 15 days from today', 'error'); return false;}
        if (new Date(formData.agreementValidUntil) < new Date()) {showMessage('Please select the agreement valid date greater than current date', 'error'); return false;}
        if (new Date(formData.agreementValidUntil) > new Date(getFutureDate(5, 'years'))) {showMessage('Please select the agreement date less than 5 years from today', 'error'); return false;}
        
        const recipientErrors = allRecipients.map((recipient) => {
            const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            if (!emailPattern.test(recipient.email)) {showMessage('Please enter valid email address', 'error'); return false;}
            if (recipient.mobileNum.length !== 10)  {showMessage('Please enter valid mobile number', 'error'); return false;}
            return true;
        })
        return !recipientErrors.includes(false)
    }

    function handleDragOver(e) {
        e.preventDefault();
        e.stopPropagation();
    }

    function handleOnDrop(e) {
        e.preventDefault()
        e.stopPropagation();
        setFiles([...files, ...e.dataTransfer.files])
    }


    const onFormDataChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const onRecipientDataChange = (e, id) => {
        const updatedRecipients = []
        allRecipients.forEach((recipients, index) => {
            if (index === id) {
                let new_recipients = { ...recipients, [e.target.name]: e.target.value }
                if (e.target.name === 'email') {
                    const splitName = e.target.value.split('@')[0]
                    new_recipients = { ...new_recipients, name: splitName }
                }
                updatedRecipients.push(new_recipients)
            } else updatedRecipients.push(recipients)
        })
        setAllRecipients([...updatedRecipients])
    }

    function addRecipient() {
        const recipients = [...allRecipients, { email: '', name: '', mobileNum: '' }]
        setAllRecipients([...recipients])
    }

    function onChangeDate(event) {
        const date = new Date(event.target.value)
        // TODO : Convert date
        // setFormData({...formData, [event.target.name]: `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`})
        setFormData({...formData, [event.target.name]: event.target.value})
    }

    async function saveData() {
        const isRequiredDataFilled = validateForm()
        const headers = {
            'Content-Type': 'multipart/form-data',
            'AuthToken': userAuthToken
        }
        
        if (isRequiredDataFilled) {
            dispatch(setUploadedDocument(documents))
            const fd = new FormData();
            fd.append('document', files[0])
            fd.append('metaData', JSON.stringify({
                "documentName": formData.documentName,
                "isDocumentHasPassword": false,
                "docPassword": ""
            }))

            try {
                const response = await axios.post(`${esignAPI}/uploadDocument`, fd, { headers })
                if (response.data.message === 'SUCCESS') {
    
                    const bodyData = {
                        "visibleEsignSetting": formData.visibleEsignSetting,
                        "esignValidity": `${new Date(formData.agreementValidUntil).getDate()}-${new Date(formData.agreementValidUntil).getMonth() + 1}-${new Date(formData.agreementValidUntil).getFullYear()}`, //need to convert into number of days
                        "deliveryMode": formData.deliveryMode.toLocaleUpperCase(), //MOBILE or EMAIL
                        "docId": response.data.response.docId, // docId
                        "esignRemainder": formData.reminderInDays, //days
                        "esignExpiry": `${new Date(formData.dayToComplete).getDate()}-${new Date(formData.dayToComplete).getMonth() + 1}-${new Date(formData.dayToComplete).getFullYear()}`, //need to convert into number of days
                        "authType": formData.authType.toLocaleUpperCase(), //MOBILE or EMAIL or BOTH
                        "esignReciptants": allRecipients
                    }
    
                    const response2 = await axios.post(`${esignAPI}/updateEsignSetting`, bodyData, { headers: {'AuthToken': userAuthToken} })
                    if (response2.data.message === 'SUCCESS') {
                        dispatch(setCurrentDocumentId(response.data.response.docId))
                        navigate(`/documentViewer?documentId=${response.data.response.docId}&viewType=edit`)
                    }
                }
            } catch (error) {
                showMessage(error.response.data.Response.response, 'error')
            }

        }
    }

    useEffect(() => {
        if (new Date(formData.dayToComplete).toLocaleDateString() === new Date().toLocaleDateString()) setFormData({...formData, reminderInDays: 0})
        else setFormData({...formData, reminderInDays: 1})
    }, [formData.dayToComplete])

    return (
        <div className='w-full h-screen pt-16'>
            <h1 className='text-4xl h-[10%] text-start py-10 px-16 bg-gray-100 flex items-center text-gray-600 font-semibold'>Sign Yourself</h1>
            <div className='max-h-[80%] shadow-xl overflow-auto'>
                <div className='grid grid-cols-12'>
                    {
                        documents.map((document, index) => {
                            return (
                                <div key={index} className='bg-gray-100 col-span-3 mt-10 m-16 w-72 h-72 flex flex-col rounded-xl justify-center items-center'>
                                    {formData.documentName}
                                </div>
                            )
                        })
                    }
                    <label htmlFor="upload_button">
                    <div className='bg-gray-100 col-span-3 mt-10 m-16 w-72 h-72 flex flex-col rounded-xl justify-center items-center' onDrop={handleOnDrop} onDragOver={handleDragOver}>
                        <DocumentTextIcon className='w-24 h-24 text-gray-300 font-thin' />
                        <span className='text-gray-700 text-2xl mt-6 font-medium'>Drag File Here</span>
                        <span className='text-gray-500 mt-3'>Or</span>
                            <button className='flex items-center mt-3 text-lg space-x-4 justify-center bg-[#353535] py-3 px-8 hover:bg-gray-900 text-white rounded-lg' onClick={handleButtonClick}>
                                <span>Add Attachment</span>
                                <PaperClipIcon className='w-5 h-5' />
                            </button>
                            <input
                                id='upload_button'
                                type="file"
                                ref={fileInputRef}
                                style={{ display: "none" }}
                                accept=".pdf,.docx" 
                                onChange={handleFileChange}
                            />
                    </div>
                </label>
                </div>
                <div className='m-16 flex my-10 space-x-6 items-center'>
                    <span className='text-lg text-gray-800'>Document Name</span>
                    <input type="text" name="documentName" value={formData.documentName} onChange={onFormDataChange} className='border border-gray-300 rounded-lg py-2 px-2 w-96' />
                </div>
                <div className='m-16 flex flex-col items-start my-20 space-y-10'>
                    <h3 className='text-3xl'>Add Recipients</h3>
                    <div className='space-y-10'>
                        {
                            allRecipients.map((recipient, index) => {
                                return (
                                    <div key={index} className='flex w-full bg-white space-x-5'>
                                        <span className='border flex items-center justify-center text-center w-16 border-gray-300 py-2 rounded-lg' >{index + 1}</span>
                                        
                                        <div className='grid grid-cols-12 space-x-5'>
                                            <input type="email" onChange={(e) => onRecipientDataChange(e, index)} name='email' value={recipient.email} className='border border-gray-300 col-span-4 py-2 rounded-lg px-3' placeholder='Enter your email' />
                                            <input type="text" onChange={(e) => onRecipientDataChange(e, index)} name='name' value={recipient.name} className='border border-gray-300 py-2 col-span-4 rounded-lg px-3' placeholder='Enter your name' />
                                            <input type="number" onChange={(e) => onRecipientDataChange(e, index)} name='mobileNum' value={recipient.mobileNum} className='border border-gray-300 py-2 col-span-4 rounded-lg px-3' placeholder='Enter your Mobile Number' />
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <button className='border rounded-lg px-6 py-3 text-xl' onClick={() => addRecipient()}>+ Add Recipients</button>
                </div>
                <div className='m-16 flex flex-col items-start my-20 space-y-10'>
                    <div className='flex items-center space-x-5'>
                        <h3 className='text-3xl'>More Settings</h3>
                        <ChevronDownIcon className='w-7 h-7' />
                    </div>
                    <div className='flex my-10 space-x-6 items-center justify-between w-[37rem]'>
                        <span className='text-lg text-gray-800'>Day to Complete</span>
                        <input type="date" name="dayToComplete" value={formData.dayToComplete} onChange={(e) => onChangeDate(e)} className='w-96 border border-gray-300 rounded-lg py-2 px-3'/>
                        
                    </div>
                    <div className='flex my-10 space-x-6 items-center justify-self-start'>
                        <span className='text-lg text-gray-800 mr-2'>Visibility of Esign Setting</span>
                        <Switch
                            checked={formData.visibleEsignSetting}
                            onChange={() => setFormData({ ...formData, visibleEsignSetting: !formData.visibleEsignSetting })}
                            className={[formData.visibleEsignSetting ? "flex-row-reverse group bg-green-400 text-white relative flex h-8 w-20 border cursor-pointer rounded-full p-1 transition-colors duration-200 ease-in-out focus:outline-none data-[focus]:outline-1 items-center justify-between" : "group bg-red-500 text-white relative flex h-8 w-20 border cursor-pointer rounded-full p-1 transition-colors duration-200 ease-in-out focus:outline-none data-[focus]:outline-1 items-center justify-between"]}
                        >
                            <span
                                aria-hidden="true"
                                className="pointer-events-none inline-block bg-white size-5 border translate-x-0 rounded-full ring-0 shadow-lg transition duration-500 ease-in-out"
                            />
                            <span className='p-2'>
                                {formData.visibleEsignSetting ? 'On' : 'Off'}
                            </span>
                        </Switch>
                    </div>
                    <div className='flex my-10 space-x-6 items-center justify-between w-[37rem]'>
                        <span className='text-lg text-gray-800'>Agreement valid until</span>
                        <input type="date" name="agreementValidUntil" value={formData.agreementValidUntil} onChange={(e) => onChangeDate(e)} className='w-96 border border-gray-300 rounded-lg py-2 px-3'/>
                    </div>
                    <div className='flex my-10 space-x-6  items-center justify-between w-[37rem]'>
                        <span className='text-lg w-fit text-gray-800'>Authentication Type</span>
                        <div className='w-96'>
                            <SelectBox list={[{ id: '1', name: 'Mobile' }, { id: '2', name: 'Email' }, { id: '3', name: 'EMAIL_MOBILE' }]} updateSelectedValue={(value) => setFormData({...formData, authType: value.name})} />
                        </div>
                    </div> 
                    <div className='flex my-10 space-x-6 items-center justify-between w-[37rem]'>
                        <span className='text-lg text-gray-800'>Delivery Mode</span>
                        <div className='w-96'>
                            <SelectBox list={[{ id: '1', name: 'Mobile' }, { id: '2', name: 'Email' }, { id: '3', name: 'EMAIL_MOBILE' }]} updateSelectedValue={(value) => setFormData({...formData, deliveryMode: value.name})} />
                        </div>
                    </div>
                    
                    <div className='flex my-10 space-x-6 items-center justify-self-start'>
                        <span className='text-lg text-gray-800 mr-2 -mt-7'>Automatic Reminders</span>
                        <div className='flex flex-col'>
                            <Switch
                                checked={enabled}
                                onChange={setEnabled}
                                className={[enabled ? "flex-row-reverse group bg-green-400 text-white relative flex h-8 w-20 border cursor-pointer rounded-full p-1 transition-colors duration-200 ease-in-out focus:outline-none data-[focus]:outline-1 items-center justify-between" : "group bg-red-500 text-white relative flex h-8 w-20 border cursor-pointer rounded-full p-1 transition-colors duration-200 ease-in-out focus:outline-none data-[focus]:outline-1 items-center justify-between"]}
                            >
                                <span
                                    aria-hidden="true"
                                    className="pointer-events-none inline-block bg-white size-5 border translate-x-0 rounded-full ring-0 shadow-lg transition duration-500 ease-in-out"
                                />
                                <span className='p-2'>
                                    {enabled ? 'On' : 'Off'}
                                </span>
                            </Switch>
                            <span className='w-full text-gray-500'>
                                Automatic reminders will only be delivered via email, even if the delivery mode is set to 'Email+SMS'.
                            </span>
                        </div>
                    </div>
                    <div className={`${new Date(formData.dayToComplete).toLocaleDateString() === new Date().toLocaleDateString() && 'text-gray-400'} flex my-10 space-x-6 items-center`}>
                        <span className='text-lg'>Send a reminder in every</span>
                        <input type="number" onChange={onFormDataChange} value={formData.reminderInDays} name="reminderInDays" disabled={new Date(formData.dayToComplete).toLocaleDateString() === new Date().toLocaleDateString()} className={`${new Date(formData.dayToComplete).toLocaleDateString() === new Date().toLocaleDateString() && 'cursor-not-allowed text-gray-400'} border max-w-16 border-gray-300 rounded-lg py-2 px-2 w-96`} />
                        <span>Days</span>
                    </div>
                </div>
            </div>
            <div className='space-x-5 z-50  border items-center shadow-xl h-[10%] flex px-16'>
                <button className='border py-3 px-10 h-fit text-white bg-blue-900 bg- border-blue-900 rounded-lg' onClick={() => saveData()}>Continue</button>
                <button className='border py-3 px-10 h-fit bg-gray-300 rounded-lg'>Cancel</button>
            </div>
        </div >
    )
}
