import React from 'react'
import Title from '../../components/generic/Title'
import AdminDetails from '../../components/superAdmin/AdminDetails'

const CreateAdmin = () => {
  return (
    <div className='mt-16 w-full'>
      <Title title="Create Admin" />
      <AdminDetails />
    </div>
  )
}

export default CreateAdmin
